import React from "react"

import Container from "./Container"
import Text from "./Text"
import Content from "../About/Content"

const CodeOfConduct = () => (
  <Container>
    <Content>
      <Text>
        <h1>Code of Conduct</h1>
        McHacks aims to be a hackathon where all participants and volunteers
        feel welcome, included, respected and safe. All attendees–including
        sponsors, students, speakers, judges, volunteers, and organizers–are
        therefore required to abide by the following code of conduct at all
        times.
        <h2>Summary</h2>
        <p>
          Our hackathon is dedicated to providing a harassment-free space for
          everyone, regardless of gender, age, sexual orientation, ability,
          physical appearance, race, or religion.
          <br></br>
          <br></br>
          We do not tolerate harassment in any form from participants. Sexual
          language and inappropriate imagery is not appropriate for submitted
          projects or at any time or place during the event, including talks,
          workshops, receptions, and online media. Attendees violating these
          rules may be sanctioned or expelled from the event without travel
          reimbursement at the discretion of the event organizers. Any events
          that violate the McHacks Code of Conduct should be reported as
          specified in the procedures at the end of the document.
          <br></br>
          <br></br>
          Additionally, attendees are expected to comply with the&nbsp;
          <a href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf">
            Major League Hacking (MLH) code of conduct all times.
          </a>
        </p>
        <h2>Full Version</h2>
        <p>
          Harassment includes, but is not limited to: offensive verbal comments
          related to gender, age, sexual orientation, ability, physical
          appearance, race, religion; sexual or graphic images in public spaces;
          deliberate intimidation; stalking, following, harassing; photography
          or video recording someone without their consent; sustained disruption
          of talks or other events; inappropriate physical contact; and
          unwelcome sexual attention.
          <br></br>
          <br></br>
          Participants exhibiting any of these harmful behaviours will be asked
          to stop immediately. Sponsors, speakers, volunteers and organizers are
          also expected to follow the anti-harassment policy. In particular,
          sponsors should not use sexualized images, ctivities, or other graphic
          materials. Booth staff (including volunteers) should not use
          sexualized clothing/uniforms/costumes, or otherwise create a
          sexualized environment.
          <br></br>
          <br></br>
          In addition, hacks or projects submitted by teams that violate this
          code may be disqualified or denied the right to demo at the discretion
          of the event organizers.
          <br></br>
          <br></br>
          Any bus ticket reserved by a participant with a deposit may not be
          traded, redistributed, or sold to any other participant or person. If
          a participant wishes to unreserve a ticket and receive a refund, they
          must send an email to&nbsp;
          <a href="mailto:contact@mchacks.ca" target="_top">
            contact@mchacks.ca
          </a>
          &nbsp;along with an order receipt detailing information about the
          transaction.
          <br></br>
          <br></br>
          Photography is encouraged, but participants must be given a reasonable
          chance to opt out from being photographed. If they object to being
          photographed, please comply with their request. It is inappropriate to
          take photographs in contexts where people have a reasonable
          expectation of privacy, for example in bathrooms or when participants
          are sleeping.
          <br></br>
          <br></br>
          If a participant engages in harmful behaviour, the event organisers
          may take any action they deem appropriate, including warning the
          perpetrator or expelling them from the event with no reimbursement.
          <br></br>
          <br></br>
          If you are being harassed, notice that someone else is being harassed,
          or have any other concerns, please follow the reporting procedures at
          the bottom of this document and report the incident immediately.
          Hackathon staff will be clearly identified and the Co-Directors can be
          reached at all times via the phone numbers below.
          <br></br>
          <br></br>
          Hackathon staff will be happy to help participants contact venue
          security or local law enforcement, provide escorts, or otherwise
          assist those experiencing harassment to feel safe for the duration of
          the event. We value your attendance and expect participants to follow
          these rules at hackathon and workshop venues and all related social
          events.
        </p>
        <h2>Reporting Procedures</h2>
        <p>
          If you feel uncomfortable or think there may be a potential violation
          of the code of conduct, please report it through the following
          methods. All reporters have the right to remain anonymous.
          <br></br>
          <br></br>
          Please also don’t hesitate to contact any of the organizers or the
          security guard on duty at any time. You can get in direct touch with
          the Co-Directors below:
          <ul>
            <li>
              <b>Isabelle del Mundo</b>: (940) 594-5583{" "}
            </li>
            <li>
              <b>Max Boksem</b>: (438) 885-7116
            </li>
          </ul>
          You can also email us at&nbsp;
          <a href="mailto:directors@mchacks.ca" target="_top">
            directors@mchacks.ca
          </a>
          .<br></br>
          <br></br>
          If you are uncomfortable reporting a potential violation to the
          Co-Directors for any reason, please approach the contracted security
          personnel or MLH Coaches (phone number below) on hand for assistance.
          <br></br>
          <br></br>
          If you are in need of emergency help, please contact the following
          resources immediately:
          <br></br>
          <br></br>
          <b>Montreal Police/Fire/Paramedics</b>
          <ul>
            <li>Emergency: 911</li>
            <li>Non-Emergency: (514) 280-2222</li>
          </ul>
          <b>MLH Incident Reporting:</b> (409) 202-6060
          <br></br>
          <br></br>
          <b>
            Sexual Assault Centre of the McGill Students’ Society (SACOMSS):{" "}
          </b>{" "}
          (514) 398-8500
          <br></br>
          <br></br>
          <hr size="1"></hr>
          <br></br>
          Credit for this code of conduct go to the following codes of conduct:
          <ul>
            <li>Major League Hacking Code of Conduct</li>
            <li>Hack Code of Conduct</li>
            <li>Hack@Brown Code of Conduct</li>
            <li>JustHack Code of Conduct</li>
          </ul>
          If you have any questions regarding this code of conduct, please
          contact&nbsp;
          <a href="mailto:contact@mchacks.ca" target="_top">
            contact@mchacks.ca
          </a>
          . McHacks reserves itself the right to modify this code of conduct at
          any time.
          <br></br>
          <br></br>
          Last modified on November 25, 2019.
        </p>
      </Text>
    </Content>
  </Container>
)

export default CodeOfConduct
